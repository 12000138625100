<template>
  <div class="quizpreview row align-items-center">
    <div class="col-12">
      <div class="question" v-html="quiz.question" />
    </div>
    <div class="options col-12">
      <fieldset class="form-group">
        <legend class="col-form-label">Options:</legend>
        <b-overlay opacity="0" :show="preview">
          <template v-slot:overlay><p /></template>
          <div role="group">
            <div v-for="(v, i) in quiz.options" :key="`quiz_options_key_${i}`">
              <span class="mr-2" :class="{transparent: (!isCorrectAnswer(i) && !isUserAnswer(i))}" v-if="graded">
                <CorrectIcon class="text-success" v-if="isCorrectAnswer(i)" />
                <WrongIcon class="text-danger" v-else />
              </span>
              <div class="custom-control custom-checkbox custom-control-inline" v-if="quiz.allow_multiple_answers">
                <input type="checkbox" class="custom-control-input" v-model="checkboxChecked" :value="i" @change="checkboxChanged" :id="`quiz_option_${i}`" name="quiz_option" />
                <label class="custom-control-label" :for="`quiz_option_${i}`">
                  <span class="mr-2">{{convertIndex2Alpha(i)}}.</span>
                  <span>{{v}}</span>
                </label>
              </div>
              <div class="custom-control custom-radio custom-control-inline" v-else>
                <input type="radio" class="custom-control-input" v-model="radioSelected" :value="i" @change="radioChanged" :id="`quiz_option_${i}`" name="quiz_option" />
                <label class="custom-control-label" :for="`quiz_option_${i}`">
                  <span class="mr-2">{{convertIndex2Alpha(i)}}.</span>
                  <span>{{v}}</span>
                </label>
              </div>
            </div>
          </div>
        </b-overlay>
      </fieldset>
    </div>
    <div class="col-12" v-if="graded">
      <p class="text-success" v-if="quiz.correct_answer">Correct! You got {{ quiz.point }} point(s).</p>
      <p class="text-danger" v-else>Wrong!</p>
      <p class="text-success">
        <span v-if="quiz.allow_multiple_answers">Correct answers are: </span>
        <span v-else>Correct answer is: </span>
        {{ getAnswersInAlpha() }}
      </p>
      <p class="text-success">{{ quiz.explanation }}</p>
    </div>
    <div class="col-12" v-else-if="preview">
      <p>Explanation: {{ quiz.explanation }}</p>
    </div>
    <div class="col-12">
      <PointIcon /> <small class="text-success">+{{ quiz.point }}</small> <small class="mr-5">point(s)</small>
      <span v-if="preview">
        <AuthorIcon class="text-muted" /> <small>{{ quiz.author }}</small>
      </span>
    </div>
  </div>
</template>

<script>
import PointIcon from 'mdi-vue/FileCheckOutline';
import AuthorIcon from 'mdi-vue/AccountMultipleOutline';
import CorrectIcon from 'mdi-vue/Check';
import WrongIcon from 'mdi-vue/Close';

export default {
  name: 'QuizPreview',
  components: {
    PointIcon, AuthorIcon, CorrectIcon, WrongIcon,
  },

  props: {
    value: { type: Array, default: null },
    quiz: {
      type: Object,
      default() { return {}; },
    },
    preview: { type: Boolean, default: true },
    graded: { type: Boolean, default: false },
  },

  data() {
    return {
      radioSelected: '',
      checkboxChecked: [],
    };
  },

  methods: {
    initSelectedItems() {
      let selectedItems = this.value;
      if (!selectedItems) selectedItems = this.graded ? this.quiz.user_answers : this.quiz.answers;
      if (selectedItems && selectedItems.length) {
        if (this.quiz.allow_multiple_answers) this.checkboxChecked = [...selectedItems];
        else [this.radioSelected] = selectedItems;
      }
    },
    checkboxChanged() {
      this.$emit('input', this.checkboxChecked);
    },
    radioChanged() {
      this.$emit('input', [this.radioSelected]);
    },
    isUserAnswer(idx) {
      return (this.quiz.user_answers && this.quiz.user_answers.length && this.quiz.user_answers.filter(x => x === idx).length);
    },
    isCorrectAnswer(idx) {
      return this.quiz.answers.filter(x => x === idx).length;
    },
    convertIndex2Alpha(idx) {
      return String.fromCharCode(idx + 65); // 65 = ('A'.charCodeAt(0) + 1). 1 is added because idx is zero-based.
    },
    getAnswersInAlpha() {
      return this.quiz.answers.map(x => this.convertIndex2Alpha(x)).sort().join(', ');
    },
  },

  created() {
    this.initSelectedItems();
  },
};
</script>

<style lang="stylus" scoped>

.quizpreview
  .question
    overflow hidden
    >>> img
      max-width 10rem
  .options
    .transparent
      color transparent
      opacity 0

</style>
